/* Projects Section */

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr)); /* Maximum 3 columns */
  gap: 2rem; /* Ensure spacing works across browsers */
  justify-content: center; /* Center grid content */
  align-items: stretch; /* Ensure cards align properly */
}

.projects-section {
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #37474f;
  min-height: 100vh;
  display: flex; /* Ensure it's a block or flex container */
  flex-direction: column;
}

/* Section Title */
.projects-title {
  text-align: center;
  font-size: 2.5rem;
  color: #263238;
  margin-bottom: 2rem;
}

/* Project Card */
.project-card {
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  justify-content: space-between;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 350px; /* Ensure all project cards have a consistent minimum height */
  flex-grow: 1; /* Allow cards to stretch if needed */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Prevent content from overflowing */
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Project Title */
.project-title {
  font-size: 1.5rem;
  color: #263238;
  margin-bottom: 0.5rem;
}

/* Project Description */
.project-description {
  font-size: 1rem;
  color: #546e7a;
  margin-bottom: 1rem;
}

/* Technologies Used */
.project-tech {
  font-size: 0.9rem;
  color: #607d8b;
  margin-bottom: 1.5rem;
}

/* Project Links */
.project-links {
  display: flex;
  justify-content: space-between;
  gap: 1rem; /* Add space between the buttons */
  margin-top: auto; /* Push links to the bottom */
}

.project-link {
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #607d8b;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: auto;
  max-width: 100%; /* Prevent button from exceeding card width */
  min-width: 100px; /* Maintain consistent button width */
  box-sizing: border-box; /* Include padding in the button size */
}

.github-link {
  background-color: #37474f;
  color: white;
}

.github-link:hover {
  background-color: #263238;
}

.demo-link {
  background-color: #607d8b;
  color: white;
}

.demo-link:hover {
  background-color: #546e7a;
}

/* Tag Badge */
.project-tag {
  display: inline-block;
  background-color: #607d8b;
  color: white;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  align-self: flex-start;
}

/* --- Responsive Styles --- */

/* Tablet Screens (max-width: 768px) */
@media screen and (max-width: 768px) {
  .projects-grid {
    gap: 1.5rem; /* Maintain gap between cards */
    grid-template-columns: 1fr; /* Single column */
    gap: 1.5rem !important; /* Ensure vertical spacing */
  }
  /* Projects Section */
  .projects-section {
    padding: 1.5rem;
  }

  /* Section Title */
  .projects-title {
    font-size: 2rem;
  }

  /* Project Card */
  .project-card {
    padding: 1.2rem;
    margin-bottom: 1.5rem; /* Add vertical space between cards */
  }

  /* Project Title */
  .project-title {
    font-size: 1.25rem;
  }

  /* Project Description */
  .project-description {
    font-size: 0.9rem;
  }

  /* Project Tech */
  .project-tech {
    font-size: 0.85rem;
  }

  /* Project Links */
  .project-links {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Stretch buttons to full width */
    gap: 0.8rem; /* Add vertical space between buttons */
  }

  .project-link {
    width: 100%; /* Make buttons take full width */
    max-width: 100%; /* Prevent overflow */
  }
}

/* Mobile Screens (max-width: 480px) */
@media screen and (max-width: 480px) {
  .projects-grid {
    display: grid !important;
    grid-template-columns: 1fr; /* Ensure cards stack vertically */
    gap: 1.5rem !important; /* Vertical space between stacked cards */
  }
  /* Projects Section */
  .projects-section {
    padding: 1rem;
  }

  /* Section Title */
  .projects-title {
    font-size: 1.8rem;
  }

  /* Project Card */
  .project-card {
    padding: 1rem;
    margin-bottom: 1.5rem !important; /* Add vertical space between cards */
  }

  /* Project Title */
  .project-title {
    font-size: 1.2rem;
  }

  /* Project Description */
  .project-description {
    font-size: 0.85rem;
  }

  /* Project Tech */
  .project-tech {
    font-size: 0.8rem;
  }

  /* Project Links */
  .project-links {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Stretch buttons to full width */
    gap: 0.5rem; /* Add vertical space between buttons */
  }

  .project-link {
    width: 100%; /* Make buttons take full width */
    max-width: 100%; /* Prevent buttons from overflowing */
    padding: 0.5rem 0.8rem; /* Reduce padding for smaller screens */
    font-size: 0.9rem; /* Adjust font size */
  }
}

/* Safari-Specific Fix */
@supports (-webkit-touch-callout: none) {
  .project-card {
    min-height: 350px; /* Fix height issues on Safari */
  }
}