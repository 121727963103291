body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: border-box; /* Ensure consistent sizing */
}

*, *::before, *::after {
  box-sizing: inherit; /* Inherit consistent box-sizing */
}
