/* src/components/Header.css */

header.responsive-header {
  background-color: #FFFFFF;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

nav {
  display: flex;
  align-items: center;
  width: 100%; /* Use full width of the header */
}

.nav-links {
  display: flex;
  list-style: none; /* Remove bullets */
  gap: 1rem; /* Add space between navigation items */
  margin: 0;
  padding: 0;
}

.nav-links li a {
  font-family: 'Roboto', sans-serif; /* Use modern font */
  color: #546E7A; /* Text color */
  text-decoration: none; /* Remove underline */
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #37474F; /* Slightly darker color on hover */
}

.nav-links li a.active {
  color: #263238; /* Active link color */
  font-weight: 700; /* Optional bold for active state */
}

/* Push social links to the far right */
.social-links {
  display: flex;
  list-style: none; /* Remove bullets */
  margin: 0;
  padding: 0;
  gap: 1rem; /* Add space between social icons */
  margin-left: auto; /* Push social links to the right */
}

.social-links li a {
  color: #546E7A; /* Icon color */
  transition: color 0.3s ease;
}

.social-links li a:hover {
  color: #263238; /* Darker hover color */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  header.responsive-header {
    padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
  }

  .nav-links {
    gap: 0.5rem; /* Reduce space between nav links */
  }

  .nav-links li a {
    font-size: 0.9rem; /* Smaller font size for smaller screens */
  }

  .social-links {
    gap: 0.5rem; /* Reduce gap for social icons */
  }

  .social-links li a {
    font-size: 1.2rem; /* Scale down icons slightly */
  }
}

@media screen and (max-width: 480px) {
  header.responsive-header {
    padding: 0.5rem; /* Minimal padding for very small screens */
  }

  .nav-links li a {
    font-size: 0.85rem; /* Smaller nav link font size */
  }

  .social-links li a {
    font-size: 1rem; /* Further reduce icon size */
  }
}