/* General styles */
h1, h2, p {
  margin: 0;
}

.home {
  font-family: 'Roboto', sans-serif;
  padding: 2rem;
  line-height: 1.6;
  color: #546e7a;
}

/* Main Section */
.main-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-top: 4rem;
}

.main-section .info {
  max-width: 50%;
}

.main-section h1 {
  font-size: 2.5rem;
  color: #263238;
  margin-bottom: 1rem;
}

.main-section .subtitle {
  font-size: 1.5rem;
  color: #607D8B;
  margin-bottom: 2rem;
}

.main-section .motivation {
  font-size: 1.1rem;
}

.main-section .picture {
  width: 40%;
  text-align: center;
}

.main-section .picture img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Pillars Section */
.pillars {
  text-align: center;
  margin-bottom: 4rem;
}

.pillars h2 {
  font-size: 2rem;
  color: #263238;
  margin-bottom: 2rem;
}

.pillars p {
  margin-bottom: 2rem;
}

.pillar-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.pillar {
  background: #f5f5f5;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.pillar h3 {
  font-size: 1.5rem;
  color: #263238;
  margin-bottom: 1rem;
}

.pillar p {
  font-size: 1rem;
  color: #546e7a;
}

.pillar-link {
  display: inline-block;
  margin-top: 1rem;
  text-decoration: none;
  color: white;
  background-color: #607d8b;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pillar-link:hover {
  background-color: #263238;
}

/* Get in Touch Section */
.get-in-touch {
  text-align: center;
  margin-top: 4rem;
}

.get-in-touch h2 {
  font-size: 2rem;
  color: #263238;
  margin-bottom: 1rem;
}

.get-in-touch p {
  font-size: 1.1rem;
  color: #546e7a;
  margin-bottom: 2rem;
}

.contact-button {
  background-color: #263238;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #37474f;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  /* Main Section */
  .main-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .main-section .info {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .main-section .picture {
    width: 80%;
  }

  /* Pillars Section */
  .pillar-columns {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  /* Get in Touch Section */
  .get-in-touch h2 {
    font-size: 1.5rem;
  }

  .get-in-touch p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  /* Pillars Section */
  .pillar-columns {
    grid-template-columns: 1fr;
  }

  .pillar {
    padding: 1rem;
  }

  .pillar h3 {
    font-size: 1.25rem;
  }

  /* Get in Touch Section */
  .get-in-touch h2 {
    font-size: 1.25rem;
  }

  .contact-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}