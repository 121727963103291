.resume {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 800px;
  color: #333;
  border: none; /* Ensure no border exists */
  background: none; /* Remove background styles */
  padding: 1rem; /* Add padding for mobile screens */
}

.resume-header {
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.resume-header h1 {
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.resume-header p {
  margin: 0.3rem 0;
  font-size: 1rem; /* Ensure smaller font for descriptions */
}

.resume-section {
  margin-bottom: 2rem;
}

.resume-section h2 {
  border-bottom: 2px solid #555;
  padding-bottom: 0.3rem;
  margin-bottom: 1rem;
  font-size: 1.5rem; /* Adjust size for mobile screens */
}

.resume-subsection h3 {
  margin: 0.5rem 0;
  font-size: 1.25rem; /* Reduce font size for subsections */
}

.resume-subsection h4 {
  font-size: 1rem;
  margin: 0.2rem 0 1rem;
}

.resume-date {
  float: right;
  font-weight: normal;
  color: #777;
  font-size: 0.9rem; /* Slightly smaller for mobile */
}

.resume-subsection ul {
  list-style: none;
  padding-left: 1rem;
}

.resume-subsection li {
  margin-bottom: 0.5rem;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .resume {
    max-width: 100%; /* Use full width on smaller screens */
    padding: 1rem;
  }

  .resume-header h1 {
    font-size: 2rem; /* Reduce the header size for better fit */
  }

  .resume-header p {
    font-size: 0.9rem; /* Smaller font size for descriptions */
  }

  .resume-section h2 {
    font-size: 1.25rem; /* Smaller section headers */
    border-bottom-width: 1px; /* Thinner border for mobile */
  }

  .resume-subsection h3 {
    font-size: 1.1rem;
  }

  .resume-subsection h4 {
    font-size: 0.9rem;
  }

  .resume-date {
    float: none; /* Stop floating for better stacking */
    display: block; /* Display on its own line */
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .resume-header h1 {
    font-size: 1.8rem; /* Further reduce header size */
  }

  .resume-header p {
    font-size: 0.85rem;
  }

  .resume-section h2 {
    font-size: 1.2rem;
  }

  .resume-subsection h3 {
    font-size: 1rem;
  }

  .resume-subsection h4 {
    font-size: 0.85rem;
  }

  .resume-date {
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }
}

@media print {
  button {
    display: none; /* Hide the button in the PDF */
  }

  .resume-section {
    page-break-inside: avoid; /* Prevent sections from splitting across pages */
  }

  body {
    -webkit-print-color-adjust: exact; /* Ensure colors print correctly */
  }
}